import React from "react";
import ProjectShowcase from "./component/project-showcase";
import Biesjefkeimg from "./img/biesjefke.png";
import biesjefke1 from "./img/biesjefke/biesjefke (1).png";
import biesjefke2 from "./img/biesjefke/biesjefke (2).png";
import biesjefke3 from "./img/biesjefke/biesjefke (3).png";
import biesjefke4 from "./img/biesjefke/biesjefke (4).png";
function Biesjefke() {
  const processSteps = [
    {
      text:
      <>
      
      To kickstart the project, I began by carefully crafting the website's design using <span style={{ fontWeight: "bold" }}>Figma</span> . Collaborating closely with the restaurant owners, I ensured that the website's aesthetic and <span style={{ fontWeight: "bold" }}> user experience </span>aligned with their vision and <span style={{ fontWeight: "bold" }}>  brand identity</span>. After receiving their approval on the design, I proceeded to the development phase.</>,
    },
    {
      text:<>I used <span style={{ fontWeight: "bold" }}> ReactJS</span> to create a website focused on making dining easier and more enjoyable. One key feature is the <span style={{ fontWeight: "bold" }}> reservation system</span>, where i used Formitable. This lets customers easily book tables online, making things convenient for both them and the restaurant. I paid close attention to detail while building the site, making sure it looked good and was easy to use. I made sure people could easily find what they needed and added engaging content to keep them interested.I <span style={{ fontWeight: "bold" }}>tested</span>  the site regularly on both mobile and desktop and I asked for regular feedback to make it better. This helped me fix any problems and make sure the site worked well for everyone. Overall, I aimed to make the best online dining experience possible.</> ,
      images: [biesjefke1, biesjefke2],
    },
    {
      images: [biesjefke3, biesjefke4],
    },
  ];
  return (
    <div>
      <ProjectShowcase
        name="Biesjefke"
        img={Biesjefkeimg}
        description="The project I undertook involved designing and developing a website for a newly opened local restaurant.

        The restaurant specialized in serving delicious brunch items and had recently added a gelato stand to its offerings. The primary objective of creating this website was to attract a larger customer base and provide them with a convenient reservation system.
        
        I made two different designs within 6 months in order to challenge my self and recreate the second design in React. For both of the designs I designed it in figma. "
        tools="Figma,
      
     ReactJs"
        role="UX/UI designer, Frontend Developer"
        link="https://bie-sjefke.nl/"
        linkName="Biesjefke's website"
        processSteps={processSteps} // Pass the array of process steps as a prop
      />
    </div>
  );
}
export default Biesjefke;
