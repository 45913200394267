import React, { useRef, useEffect, useState } from "react";
import "./Projects.css";
import boxplosive from "./img/CMS.png";
import Biesjefke from "./img/biesjefke.png";
import bolt from "./img/bolt.png";
import jumbo from "./img/jumboproject.png";
import { Link } from "react-router-dom";

const Projects = () => {
  const containerRef = useRef(null);
  const [transformAmount, setTransformAmount] = useState(0);

 
  const style = {
    transform: `translate3d(${transformAmount}px, 0, 0)`,
  };

  return (
    <section className="section-projects" id="section-projects">
      <div className="projects">
       
                  <div class="wrapper">
              <div class="marquee">
                <p>
                   Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects 
                </p>
                <p>
                    Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects Projects 
                </p>
              </div>
            </div>

       
        <Link to="/Jumbo" className="link">
          <div className="project-container">
            <p>Jumbo </p>
            <img src={jumbo} alt="jumbo" />
          </div>
        </Link>
        <br></br>
        <hr></hr>
        <br></br>
        <Link to="/Boxplosive" className="link">
          <div className="project-container">
            <p>Boxplosive</p>
            <img src={boxplosive} alt="Boxplosive" />
          </div>
        </Link>
        <br></br>
        <hr></hr>
        <br></br>
        <Link to="/Biesjefke" className="link">
          <div className="project-container">
            <p>Biesjefke</p>
            <img src={Biesjefke} alt="Biesjefke" />
          </div>
        </Link>
        <br></br>
        <hr></hr>
        <br></br>
        <Link to="/Boltit" className="link">
          <div className="project-container">
            <p>BoltIt </p>
            <img src={bolt} alt="Boltit" />
          </div>
        </Link>
        <br></br>
      </div>
    </section>
  );
};

export default Projects;
