import React from "react";
import "./App.css";
import Home from "./HomePage";
import Intro from "./intro";
import Navbar from "./Navbar";
import About from "./About";
import Projects from "./Projects";
import Footer from "./Footer";
import Jumbo from "./Jumbo";
import Boxplosive from "./Boxplosive";
import Biesjefke from "./Biesjefke";
import Boltit from "./Boltit";
import MainPage from "./MainPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrolltoTop";

function App() {
  return (
    <Router>
      <ScrollToTop />{" "}
      <div className="App">
        <Intro />
        <div className="content-container">
          <Navbar />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/Boxplosive" element={<Boxplosive />} />
            <Route path="/Biesjefke" element={<Biesjefke />} />
            <Route path="/jumbo" element={<Jumbo />} />
            <Route path="/boltit" element={<Boltit />} />
            <Route path="/home" element={<Home />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
