import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="footer" id="section-contact">
      <h1>Lets get in touch!</h1>
      <div className="socials">
        <li>
          <a href="https://www.linkedin.com/in/navisa-sabeti-a0ba671ba">
            LinkedIn
          </a>
        </li>
        <li>
          <a href="mailto:navisasabeti10@gmail.com">Email</a>
        </li>
      </div>
    </div>
  );
};
export default Footer;
