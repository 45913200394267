import React, { useState, useEffect } from 'react';
import './intro.css';

const Intro = () => {
    const [showIntro, setShowIntro] = useState(true);

    useEffect(() => {
        // Set a timeout to hide the intro after 3 seconds
        const timeout = setTimeout(() => {
            setShowIntro(false);
        }, 1000);

        // Clear the timeout when the component unmounts (e.g., when navigating away)
        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className={`intro ${showIntro ? 'show' : 'hide'}`}>
            <div className="content">
                <h2>Navisa</h2>
                <h2>Navisa</h2>
            </div>
        </div>
    );
}

export default Intro;
