import React from "react";
import ProjectShowcase from "./component/project-showcase";
import jumboimg from "./img/jumboproject.png";
import notion from "./img/jumbo/notion.png";
import wfd from "./img/jumbo/wfd.png";
import karl from "./img/jumbo/karl.png";
import kioskfinal from "./img/jumbo/kiosk final.png";
import kioskfinal1 from "./img/jumbo/kiosk final(1).png";
import kioskfinal2 from "./img/jumbo/kiosk final(2).png";
import kioskfinal3 from "./img/jumbo/kiosk final(3).png";
import kioskfinal4 from "./img/jumbo/kiosk final(4).png";
import kioskfinal5 from "./img/jumbo/kiosk final(5).png";
import kioskfinal6 from "./img/jumbo/kiosk final(6).png";
import kioskfinal7 from "./img/jumbo/kiosk final(7).png";
import kioskfinal8 from "./img/jumbo/kiosk final(8).png";
import coding from "./img/jumbo/coding2.png";

function Jumbo() {
  const processSteps = [
    {
      text: (
        <>
          For this project we made sure to use the{" "}
          <span style={{ fontWeight: "bold" }}> Design Thinking</span> approach focusing on understanding the user and their needs. For planning and organizing purposes, we decided to use{" "}
          <span style={{ fontWeight: "bold" }}> Notion</span> as our planning program. It helped us keep track of what we needed to do and who was responsible for each task.
          <br /> <br />

          We addressed the challenge of connecting online and offline shopping experiences head-on with an imagination of a new feature in the existing Jumbo app:
          <span style={{ fontWeight: "bold" }}> personalized</span>
          <span style={{ fontWeight: "bold" }}> recipes</span>. This feature, powered by AI, knows you, and your shopping habits to suggest recipes tailored just for you. What makes it unique is that it not only considers your diet preferences but also which store you usually shop from. 
          <br /> <br />

          By checking what perishable items are in stock at those stores in, the app can suggest recipes using those ingredients. This encourages users to visit the store and pick up the items they need. It's a win-win: users get the answer to the biggest question each day:
          <span style={{ fontWeight: "bold" }}> Whats for dinner?</span> by using personalized recipes, and stores can sell more perishable items, reducing waste and saving money.
        </>
      ),
      image: wfd,
    },

    {
      text: (
        <>
  <span style={{ fontWeight: "bold" }}> Meet Karl the Kiosk </span> 
          <br/><br/>
          Based on our research, we found that using <span style={{ fontWeight: "bold" }}> kiosks and QR codes</span> can actively engage the customers at the retail stores. It makes them interact more within the store and creates a memorable experience,which increases the chances of them becoming loyal customers. Therefore, we decided to include a kiosk in our plan. 
          <br/><br/>
          We even made a simple cardboard model to show stakeholders to get them interested in the idea. Doing this proved to have been a great idea since we also used the kiosk during the Fontys Innovation Insight awardshow where we were awarded with the award for the most innovative
          idea and execution.
        </>
      ),
      image: karl,
    },
   
    {
      text: (
        <>
        <span style={{ fontWeight: "bold" }}> Design Phase</span>
          <br/><br/>
          Throughout the design phase, I was responsible for the kiosk design. After the initial sketching and wireframing and a few iterations, this was the final design.
        </>
      ),
      images: [kioskfinal, kioskfinal1, kioskfinal2],
    },
    {
      images: [kioskfinal3, kioskfinal4, kioskfinal5],
    },
    {
      images: [kioskfinal6, kioskfinal7, kioskfinal8],
    },
    {
      text: (
        <>
        <span style={{ fontWeight: "bold" }}> Development Phase</span>
        <br/><br/>
           I chose to use <span style={{ fontWeight: "bold" }}> ReactJS </span>  for the development phase because it offers more flexibility than other libraries. I have used the figma design to develope the app to look accordingly. with the development my group and I were able to showcase the AI intergation and bring our idea to life . 
          <br />
          <br />
          I used <span style={{ fontWeight: "bold" }}> React + Vite </span> to create the project, allowing me to work with components. This was a perfect fit for the project. As I started developing it, I made sure that the data in the code could be easily used. This way, when the other components like <span style={{ fontWeight: "bold" }}> A.I and Database </span> were completed, I could import that data into the component I created. So, whenever you click on a recipe, the data will be displayed.
          <br />
          <br />
    
          After further reviewing the code, I have made it more ready for future data input. I have stored each part of the card I created in an array, which allows me to easily access the data from it.
        </>
      ),
      image: coding,
    },
  ];
  return (
    <div>
      <ProjectShowcase
        name="Whats for dinner"
        img={jumboimg}
        description=" This project originated from a 2-day hackathon at Jumbo JTC where my team and I were challenged to bridge the gap between online and offline shopping experiences.
        After winning the golden ticket in the hackathon, we were fortunate enough to continue on the idea and develop it further together with Jumbo with a bigger team and more time. Eventually resulting in winning the most innovative project award during the Fontys Innovation Awards"
        tools="Figma, ReactJS, Notion"
        role="UX/UI designer, Frontend Developer"
        link="https://gitlab.com/navisasabeti10/jumbo-dxd-app"
        linkName="Gitlab"
        link2="https://www.figma.com/file/j7wEJN4oi2FzqNAkdKDiWQ/DXD-Project?type=design&node-id=366%3A979&mode=design&t=RHRw403VeyP95ovy-1"
        linkName2="Figma"
        
        processSteps={processSteps} // Pass the array of process steps as a prop
      />
    </div>
  );
}
export default Jumbo;
