import React from "react";
import ProjectShowcase from "./component/project-showcase";
import boltitimg from "./img/bolt.png";

function Boltit() {
  const processSteps = [
    {
      text:<> The redesign process involved a systematic approach, starting with <span style={{ fontWeight: "bold" }}>initial sketches</span> and <span style={{ fontWeight: "bold" }}>wireframes </span> to outline the new website's structure and functionality. These early visualizations helped in conceptualizing the overall design direction and organizing content hierarchy. The wireframes were then translated into<span style={{ fontWeight: "bold" }}> interactive prototypes</span>, allowing for user testing and feedback. </>,
    },
    {
      text: <>Based on the user<span style={{ fontWeight: "bold" }}> feedback</span>, refinements were made to the prototype, ensuring an <span style={{ fontWeight: "bold" }}>  intuitive </span> and user-friendly interface.The development phase was done through Wix and it included implementing the finalized design, integrating new features such as animations, the blog with signup functionality, search capabilities, and  <span style={{ fontWeight: "bold" }}>  automated email notifications</span>.Throughout the process, regular communication with the CEO and stakeholders ensured that the project remained aligned with the company's goals.The result is a comprehensive website redesign that showcases the journey from initial sketches and wireframes to the fully developed online platform, incorporating a visually appealing design, improved functionality, and an  <span style={{ fontWeight: "bold" }}> enhanced user experience</span>.</>,
    },
  ];
  return (
    <div>
      <ProjectShowcase
        name="Boltit"
        img={boltitimg}
        description=" The BOLTIT website needed a redesign to enhance its visual appeal and user-friendliness, supporting company growth. The project included a modern design, incorporating new features such as animations, a blog with signup, and a search function. Automated email responses were integrated for user interactions. Close collaboration with the CEO ensured alignment with BOLTIT's brand identity. The redesign led to increased traffic and user engagement, showcasing its success.  "
        tools="Figma,Wix"
        role="UX/UI designer, Frontend Developer"
      
        processSteps={processSteps} // Pass the array of process steps as a prop
      />
    </div>
  );
}
export default Boltit;
