import React from "react";
import Home from "./HomePage";
import About from "./About";
import Projects from "./Projects";

function MainPage() {
  return (
    <div>
      <Home />
      <About />
      <Projects />
    </div>
  );
}

export default MainPage;
