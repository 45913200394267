import React from "react";
import ProjectShowcase from "./component/project-showcase";
import Bpimg from "./img/CMS.png";
import methodImage from "./img/boxplosive/method.png";
import personaImage from "./img/boxplosive/persona.png";
import sketch from "./img/boxplosive/sketch.png";
import sketch1 from "./img/boxplosive/sketch (1).jpg";
import sketch2 from "./img/boxplosive/sketch (2).jpg";
import sketch3 from "./img/boxplosive/sketch (3).jpg";
import wireframemainpage from "./img/boxplosive/wireframe-mainpage.png";
import wireframe from "./img/boxplosive/wireframe.png";
import prototype from "./img/boxplosive/prototype.png";
import prototype1 from "./img/boxplosive/prototype (1).png";
import prototype2 from "./img/boxplosive/prototype (2).png";
import wireframe1 from "./img/boxplosive/wireframe (1).png";
import wireframe2 from "./img/boxplosive/wireframe (1).png";
import wireframe3 from "./img/boxplosive/wireframe (1).png";
import final1 from "./img/boxplosive/final (1).png";
import final2 from "./img/boxplosive/final (2).png";
import final3 from "./img/boxplosive/final (3).png";
import final4 from "./img/boxplosive/final (4).png";

<span style={{ fontWeight: "bold" }}> word</span>

function Boxplosive() {
  const processSteps = [
    {
      text: (
        <>
        For this project, I adopted the <span style={{ fontWeight: "bold" }}> User-Centered Design</span>  methodology, which guided the assignment through a systematic process divided into several phases.
        </>
        )
    },
    {
      image: methodImage,
    },
    {
      image: personaImage,
      text:
      (
        <>  <span style={{ fontWeight: "bold" }}>Discovery Phase: </span>In this phase, I familiarized myself with the current CMS as a user and identified its problems and limitations. I focused on understanding  <span style={{ fontWeight: "bold" }}> user frustrations </span> and challenges by actively using the CMS and gathering <span style={{ fontWeight: "bold" }}>feedback</span> from users. This phase helped me gain insights into the specific areas that needed improvement. Moreover, I utilized the information gathered to create a detailed <span style={{ fontWeight: "bold" }}> persona </span>representing the target user, aiding in a better understanding of their needs.
        </>
      )
    },
    {
      images: [sketch1, sketch2, sketch3],
      text: (
        <> <span style={{ fontWeight: "bold" }}>Design Phase:</span> Based on the insights gained from the discovery phase, I began <span style={{ fontWeight: "bold" }}>sketching</span> the new CMS design using pen and paper.
        </>
      )
    },
    {
      image: wireframemainpage,
      text: 
      (
        <>
      I then transferred my initial ideas into digital wireframes using Figma. The <span style={{ fontWeight: "bold" }}>wireframes </span>allowed me to visualize the proposed changes and gather <span style={{ fontWeight: "bold" }}>feedback </span>from stakeholders and mentors. I iterated on the design based on their input, ensuring that the new design addressed the identified problems and provided a more visually appealing and functional interface.
      </>
      )
    },
    {
      images: [wireframe1, wireframe2, wireframe3],
      text:  
       (
        <>In this phase I had three different visions for the forms therefore, I created 3 wireframes to discuss it with the stakeholders.
        </>
       )
    },
    {
      images: [prototype, prototype1, prototype2],
      text: 
      (
        <>Throughout the design phase, I embraced an iterative approach, <span style={{ fontWeight: "bold" }}>continuously refining</span> and enhancing the design based on the valuable input received. I actively sought feedback from stakeholders, conducted usability tests, and incorporated user insights into subsequent iterations of the design. This <span style={{ fontWeight: "bold" }}>iterative process</span> ensured that the new design not only addressed the identified problems but also provided a <span style={{ fontWeight: "bold" }}>visually appealing</span> and functional interface that met the needs of the target users. As an example of the iteration you can take look of how to promotion-form changed throughout times.
        </>
      )
    },
    {
      text: 
      (
        <>
      <span style={{ fontWeight: "bold" }}>  Development Phase:</span> After finalizing the design, I focused on implementing the front-end of the CMS using <span style={{ fontWeight: "bold" }}>Blazor</span>, a web framework. I devoted time to learning the necessary skills in Blazor, such as creating pages and writing code. Throughout the <span style={{ fontWeight: "bold" }}>development process</span>, I continuously iterated on the design to optimize alignment and other visual aspects. I also integrated databases into the <span style={{ fontWeight: "bold" }}>CMS</span> , overcoming challenges and ensuring the functionality of database-dependent features.
        </>
      )
    },
    <li></li>,
    {
      images: [final1, final2, final3],
      text:
      (
        <>
          The entire process involved continuous collaboration and communication with stakeholders, mentors, and the development team at Boxplosive. Regular feedback sessions and usability <span style={{ fontWeight: "bold" }}>testing</span> were conducted to validate the design and gather insights for further improvements. The iterative nature of the process allowed for refinement and fine-tuning of the CMS design. By successfully completing the assignment, I gained valuable experience in <span style={{ fontWeight: "bold" }}>UX/UI design</span>, front-end development using Blazor,<span style={{ fontWeight: "bold" }}>project management</span> , usability testing, and collaboration with stakeholders. The skills and knowledge acquired throughout the process will serve as valuable assets for my future endeavors.
        </>
      )
    
    },
    {
      images: [final4, prototype2],
    },
  ];

  return (
    <div>
      <ProjectShowcase
        name="CMS recreation"
        img={Bpimg}
        description="During my internship at Boxplosive in Tilburg, Netherlands, I redesigned and developed their content management system (CMS) to improve user-friendliness.The CMS was outdated and not user-friendly, causing frustration and inefficiencies. Its limitations hindered its potential. The project aimed to create a new CMS with the same functionality but a modern, intuitive, and user-friendly interface. "
        tools="Figma,
        
        Google Material Design,    

        
        .Net Blazor,
        
        Bootstrap,
        
        Mat Blazor"
        role="UX/UI designer, Frontend Developer"
        link="https://www.figma.com/file/T8dtVh4Uusjc9a2YxiKSPO/Cms?type=design&node-id=159%3A60&mode=design&t=dkGWhMr4HCBPz0Hf-1"
        linkName="Figma"
        processSteps={processSteps} // Pass the array of process steps as a prop
      />
    </div>
  );
}

export default Boxplosive;
