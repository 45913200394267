import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import logo from "./img/Logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        behavior: "smooth",
        top: section.offsetTop,
      });
      closeMenu();
    }
  };

  const handleNavLinkClick = (sectionId) => {
    closeMenu(); // Close the menu immediately
    if (window.location.pathname === "/") {
      scrollToSection(sectionId); // Scroll without delay
    } else {
      window.location.href = `/#${sectionId}`; // Navigate to the selected section
    }
  };

  // Check if there's a hash in the URL and scroll to the corresponding section
  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      scrollToSection(hash);
    }
  }, []);

  return (
    <div className="navbar-container">
      <div className="navbar">
        <NavLink to="/" onClick={closeMenu}>
          <img className="logo" src={logo} alt="Logo" />
        </NavLink>
        <div
          className={`hamburger-menu ${isOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="bar"> </div> <div className="bar"> </div>{" "}
        </div>
        <div className="popout" style={{ display: isOpen ? "block" : "none" }}>
          <ul className="nav-parents">
            <li className="navelement">
              {" "}
              <div className="circle">1</div>{" "}
              <NavLink
                to="/"
                onClick={() => handleNavLinkClick("section-home")}
              >
                Home
              </NavLink>
            </li>
            <hr className="spacer"></hr>
            <li className="navelement">
              <div className="circle">2</div>{" "}
              <NavLink
                to="/"
                onClick={() => handleNavLinkClick("section-about")}
              >
                About
              </NavLink>
            </li>
            <hr className="spacer"></hr>
            <li className="navelement">
              {" "}
              <div className="circle">3</div>{" "}
              <NavLink
                to="/"
                onClick={() => handleNavLinkClick("section-projects")}
              >
                Projects
              </NavLink>
            </li>
            <hr className="spacer"></hr>
            <li className="navelement">
              {" "}
              <div className="circle">4</div>{" "}
              <NavLink
                to="/"
                onClick={() => handleNavLinkClick("section-contact")}
              >
                Contact
              </NavLink>
            </li>
            <hr className="spacer"></hr>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
